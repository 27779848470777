import React, {useState} from "react";
import {Button, Form, Input, Select, Switch} from "antd";
import {inviteUsers} from "../../../api/organizations";
import {showSuccess} from "../../../helpers/notifications.helper";

export const InviteForm = ({organizationId, showToggle, users}) => {
  const fileRef = React.useRef();
  const [inviteForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const onInvite = () => {
    const values = inviteForm.getFieldsValue();
    const files = fileRef.current.input.files;

    console.log("values - ", values);

    setLoading(true);
    inviteUsers(organizationId, {...values, file: files[0]})
      .then(() => {
        inviteForm.resetFields();
        showSuccess("Invites is sent");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Form
      form={inviteForm}
      layout="vertical"
      initialValues={{organizationMemberId: users?.[0].OrganizationMember?.id}}
      onFinish={onInvite}
      on
    >
      <Form.Item
        label="attach CSV file (email and companyName required)"
        name="file"
        required
      >
        <Input type="file" accept=".csv" ref={fileRef} />
      </Form.Item>
      <div className="df-row-center">
        <Form.Item
          label="Send invite from"
          name="organizationMemberId"
          required
          style={{width: "70%"}}
        >
          <Select
            value={inviteForm.getFieldValue("organizationMemberId")}
            onChange={(value) => inviteForm.setFieldValue("organizationMemberId", value)}
            options={users?.map((user) => ({
              value: user.OrganizationMember?.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
          />
        </Form.Item>
        {showToggle && (
          <Form.Item
            name="forceToFavorite"
            label="Add organizations direct to Favorites"
            valuePropName="checked"
            className="ml-4"
          >
            <Switch checkedChildren="On" unCheckedChildren="Off" />
          </Form.Item>
        )}
      </div>

      <Form.Item
        label="Direct Company Invite (Personal Message)"
        name="directInviteMessage"
        required
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Invite
        </Button>
      </Form.Item>
    </Form>
  );
};
