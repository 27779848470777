import React from "react";

export const SupplierQualification = ({activeStatus, buyerOrgMembersToNotify, users}) => {
  const matchedUsers = users
    ?.filter((user) => buyerOrgMembersToNotify?.includes(user.OrganizationMember?.id))
    .map((user) => ({
      role: user.OrganizationMember.role,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }));

  return (
    <div>
      <div className="df-row-center">
        <span className="__bold mr-4">{activeStatus ? "ENABLED" : "DISABLED"}</span>
      </div>
      {matchedUsers?.length > 0 && !!activeStatus ? (
        <div>
          <h3>Notified Members</h3>
          <ul>
            {matchedUsers.map((user) => (
              <div key={user.id}>
                <p>
                  {user.firstName} {user.lastName} - ({user.role}) - {user.email}
                </p>
              </div>
            ))}
          </ul>
        </div>
      ) : (
        <p>All owners and admins will be notified.</p>
      )}
    </div>
  );
};
